import React, {useEffect, useState} from 'react';
import {
    Drawer,
    Box,
    Typography,
    IconButton,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    List,
    ListItem,
    ListItemText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import axios from "axios";
import config from "../config";

const SidebarDetails = ({
                            isOpen,
                            onClose,
                            editedItem,
                            setEditedItem,
                            role,
                            type,
                            handleInputChange,
                            handleSaveChanges,
                            handleReportSubmit
                        }) => {
    useEffect(() => {
        console.log("Données reçues par SidebarDetails :", {
            editedItem,
            role,
            type,
        });
    }, [editedItem, role, type]); // Suivi des modifications

    const [isAddingReport, setIsAddingReport] = useState(false); // Pour afficher/cacher le formulaire
    const [newReport, setNewReport] = useState(''); // Contenu du rapport

    const handleAddReport = async (reportContent) => {
        if (!reportContent.trim()) {
            alert('Le contenu du rapport ne peut pas être vide.');
            return;
        }

        try {
            const token = localStorage.getItem('token') || sessionStorage.getItem('token');


            console.log('Envoi du rapport :', reportContent);

            const response = await axios.post(
                `${config.apiUrl}/api/tickets/${editedItem.id}/addReport`,
                { rapport: reportContent },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            console.log('Réponse de l’API :', response);

            if (response.status === 200) {
                // Ajoutez le nouveau rapport dans l'historique localement
                if (response && response.data) {
                    setEditedItem((prev) => ({
                        ...prev,
                        historiqueRapports: [...(prev?.historiqueRapports || []), response.data],
                    }));
                } else {
                    console.error('Réponse invalide de l’API :', response);
                }

                setNewReport(''); // Réinitialiser le champ
                setIsAddingReport(false); // Fermer le formulaire
                alert('Rapport ajouté avec succès.');
            } else {
                console.error('Réponse inattendue :', response);
                alert('Une erreur est survenue lors de l’ajout du rapport.');
            }
        } catch (error) {
            console.error('Erreur lors de l’ajout du rapport :', error.response || error.message);
            alert('Une erreur est survenue. Veuillez réessayer.');
        }
    };




    const isEditable =
        role === 'ROLE_ADMIN' ||
        (role === 'ROLE_CLIENT' && editedItem?.statut?.toLowerCase() === 'en attente');

    const isReportable = role === 'ROLE_ADMIN' && editedItem?.statut === 'EN_COURS';

    return (
        <Drawer
            anchor="right"
            open={isOpen}
            onClose={onClose}
            PaperProps={{ sx: { width: 800, padding: 2 } }}
        >
            <Box>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">Détails {type}</Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                {editedItem ? (
                    <Box mt={2}>
                        {/* Informations supplémentaires */}
                        <Box sx={{ mb: 3 }}>
                            <Typography variant="body1" color="textSecondary">
                                <strong>Auteur :</strong> {editedItem.clientNom || 'Non spécifié'}
                            </Typography>
                            <Typography variant="body1" color="textSecondary">
                                <strong>Date de création :</strong>{' '}
                                {editedItem.dateOuverture
                                    ? new Date(editedItem.dateOuverture).toLocaleString()
                                    : 'Non spécifiée'}
                            </Typography>
                        </Box>

                        {/* Champ titre */}
                        <TextField
                            label="Titre"
                            name="titre"
                            value={editedItem.titre || editedItem.nom || ''}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                            disabled={!isEditable}
                        />

                        {/* Champ description */}
                        <TextField
                            label="Description"
                            name="description"
                            value={editedItem.description || ''}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            multiline
                            rows={4}
                            InputLabelProps={{ shrink: true }}
                            disabled={!isEditable}
                        />

                        {/* Section des pièces jointes */}
                        {Array.isArray(editedItem?.filePaths) && editedItem.filePaths.length > 0 ? (
                            <Box mt={4}>
                                <Typography variant="h6">Pièces Jointes</Typography>
                                <List>
                                    {editedItem.filePaths.map((filePath, index) => {
                                        const fileName = filePath.split('/').pop(); // Extraire le nom du fichier
                                        const correctPath = `https://lou-ann.fr/uploads/tickets/${fileName}`; // Ajuster le chemin
                                        return (
                                            <ListItem key={index} divider>
                                                <ListItemText
                                                    primary={
                                                        <a
                                                            href={correctPath}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {fileName || 'Nom de fichier inconnu'}
                                                        </a>
                                                    }
                                                />
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </Box>
                        ) : (
                            <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                                Aucune pièce jointe disponible.
                            </Typography>
                        )}


                        {/* Sélecteur de statut */}
                        <FormControl fullWidth margin="normal" disabled={role !== 'ROLE_ADMIN'}>
                            <InputLabel>Statut</InputLabel>
                            <Select
                                label="Statut"
                                name="statut"
                                value={editedItem.statut || ''}
                                onChange={handleInputChange}
                            >
                                <MenuItem value="EN_ATTENTE">En attente</MenuItem>
                                <MenuItem value="PRIS_EN_COMPTE">Pris en compte</MenuItem>
                                <MenuItem value="EN_COURS">En cours</MenuItem>
                                <MenuItem value="TRAITE">Traité</MenuItem>
                                <MenuItem value="CLOTURE">Clôturé</MenuItem>
                                <MenuItem value="SUPPRIME">Supprimé</MenuItem>
                            </Select>
                        </FormControl>

                        {/* Bouton de sauvegarde */}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleSaveChanges(type)}
                            fullWidth
                            sx={{ mt: 2 }}
                            disabled={!isEditable}
                        >
                            Sauvegarder les modifications
                        </Button>

                        {/* Section soumission de rapport */}
                        {isReportable && (
                            <>
                                <TextField
                                    label="Rapport de résolution"
                                    name="report"
                                    value={editedItem.report || ''}
                                    onChange={(e) =>
                                        handleInputChange({
                                            target: { name: 'report', value: e.target.value },
                                        })
                                    }
                                    fullWidth
                                    margin="normal"
                                    multiline
                                    rows={4}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleReportSubmit(editedItem.report, type)}
                                    fullWidth
                                    sx={{ mt: 2 }}
                                    disabled={!editedItem.report}
                                >
                                    Soumettre le rapport
                                </Button>
                            </>
                        )}

                        {/* Historique des rapports */}
                        <Box mt={4}>
                            <Typography variant="h6">Historique des Rapports</Typography>
                            {Array.isArray(editedItem.historiqueRapports) &&
                            editedItem.historiqueRapports.length > 0 ? (
                                <List>
                                    {editedItem.historiqueRapports.map((rapport, index) => (
                                        <ListItem key={index} divider>
                                            <ListItemText
                                                primary={`Rapport #${index + 1} - Auteur : ${rapport.auteurNom || 'Auteur inconnu'} - Date : ${
                                                    rapport.date ? new Date(rapport.date).toLocaleString() : 'Date non disponible'
                                                }`}
                                                secondary={rapport.rapport || 'Pas de rapport'}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            ) : (
                                <Typography variant="body2">Aucun rapport précédent.</Typography>
                            )}
                        </Box>

                        {(role === 'ROLE_ADMIN' || role === 'ROLE_CLIENT') && editedItem?.statut !== 'CLOTURE' && (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => setIsAddingReport(true)} // Active le formulaire
                                fullWidth
                                sx={{ mt: 2 }}
                            >
                                Ajouter un Rapport
                            </Button>
                        )}

                        {isAddingReport && (
                            <Box sx={{ mt: 2 }}>
                                <TextField
                                    label="Nouveau Rapport"
                                    name="newReport"
                                    value={newReport}
                                    onChange={(e) => setNewReport(e.target.value)}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    sx={{ mb: 2 }}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleAddReport(newReport)} // Appelle la fonction pour soumettre le rapport
                                    disabled={!newReport.trim()}
                                >
                                    Soumettre le Rapport
                                </Button>
                                <Button
                                    variant="text"
                                    color="secondary"
                                    onClick={() => setIsAddingReport(false)} // Annule l’ajout
                                >
                                    Annuler
                                </Button>
                            </Box>
                        )}


                    </Box>
                ) : (
                    <Typography variant="body1">Aucun {type} sélectionné.</Typography>
                )}
            </Box>
        </Drawer>
    );

};

SidebarDetails.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    editedItem: PropTypes.object,
    setEditedItem: PropTypes.func.isRequired, // Facultatif si non transmis
    role: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    handleSaveChanges: PropTypes.func.isRequired,
    handleReportSubmit: PropTypes.func.isRequired
};
export default SidebarDetails;
