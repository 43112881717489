import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Paper, Stack, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import axios from 'axios';
import { format } from 'date-fns'; // Utilisez date-fns pour formatter les dates
import config from "../../config";

const FormTicket = ({ title, onClose, onSuccess, ticketData }) => {
    const [titre, setTitre] = useState('');
    const [description, setDescription] = useState('');
    const [statut, setStatut] = useState('en cours');
    const [categorie, setCategorie] = useState('INCIDENT'); // Par défaut "Incident"
    const [projetId, setProjetId] = useState(''); // Optionnel pour les tickets de type Projet
    const [errorMessage, setErrorMessage] = useState(null);
    const [dateOuverture, setDateOuverture] = useState('');
    const [dateFermeture, setDateFermeture] = useState('');
    const [files, setFiles] = useState([]); // Nouveau state pour les fichiers


    useEffect(() => {
        if (ticketData) {
            setTitre(ticketData.titre || '');
            setDescription(ticketData.description || '');
            setCategorie(ticketData.categorie || 'INCIDENT');
            setProjetId(ticketData.projet ? ticketData.projet.id : '');
            setDateOuverture(ticketData.dateOuverture || '');
            setDateFermeture(ticketData.dateFermeture || '');
        }
    }, [ticketData]);

    const formatDateForBackend = (date) => {
        if (!date || isNaN(new Date(date).getTime())) {
            return null;
        }
        return format(new Date(date), 'dd-MM-yy HH:mm:ss');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Vérification du token JWT
            const token = sessionStorage.getItem('token');
            if (!token) {
                setErrorMessage("Le token JWT est manquant.");
                return;
            }

            // Formatage des dates pour le backend
            const formattedDateOuverture = formatDateForBackend(dateOuverture);
            const formattedDateFermeture = formatDateForBackend(dateFermeture);

            // Construire l'objet ticket
            const ticketPayload = {
                titre,
                description,
                statut,
                categorie,
                dateOuverture: formattedDateOuverture,
                dateFermeture: formattedDateFermeture,
                projet: categorie === 'PROJET' ? { id: projetId } : null,
            };

            // Création de l'objet FormData
            const formData = new FormData();
            formData.append('ticket', new Blob([JSON.stringify(ticketPayload)], { type: 'application/json' }));

            // Ajout des fichiers (pièces jointes) si présents
            files.forEach((file) => {
                formData.append('files', file);
            });

            let response;

            if (ticketData) {
                // Modification d'un ticket existant
                response = await axios.put(`${config.apiUrl}/api/tickets/${ticketData.id}`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
            } else {
                // Création d'un nouveau ticket
                response = await axios.post(`${config.apiUrl}/api/tickets`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
            }

            // Vérification de la réponse de l'API
            if (response.status === 200 || response.status === 201) {
                console.log("Réponse de l'API :", response.data);
                onSuccess(response.data); // Callback après succès
                onClose(); // Fermer le formulaire
            } else {
                setErrorMessage(`Erreur inattendue : ${response.status}`);
            }
        } catch (error) {
            // Gestion des erreurs API
            if (axios.isAxiosError(error) && error.response) {
                console.error('Erreur API :', error.response.data);
                setErrorMessage(`Erreur API : ${error.response.data.message || error.response.statusText}`);
            } else {
                console.error('Erreur lors de la soumission du ticket :', error);
                setErrorMessage('Une erreur inattendue est survenue. Veuillez réessayer.');
            }
        }
    };


    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        const allowedExtensions = ["pdf", "docx", "xlsx", "png", "jpg"];
        const maxFileSize = 5 * 1024 * 1024; // 5MB

        const validFiles = selectedFiles.filter((file) => {
            const extension = file.name.split(".").pop().toLowerCase();
            if (!allowedExtensions.includes(extension)) {
                alert(`Le fichier ${file.name} a une extension non autorisée.`);
                return false;
            }

            if (file.size > maxFileSize) {
                alert(`Le fichier ${file.name} dépasse la taille maximale autorisée de 5MB.`);
                return false;
            }

            return true;
        });

        setFiles(validFiles);
    };


    return (
        <Paper
            elevation={6}
            sx={{
                padding: 4,
                maxWidth: 700,
                margin: 'auto',
                mt: 5,
                borderRadius: 3,
                backgroundColor: '#f9f9f9',
            }}
        >
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    display: 'grid',
                    gap: 3,
                    gridTemplateColumns: '1fr 1fr',
                    alignItems: 'center',
                }}
            >
                <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                        fontWeight: 600,
                        color: '#1976d2',
                        gridColumn: '1 / -1',
                        textAlign: 'center',
                    }}
                >
                    {ticketData ? 'Modifier le Ticket' : title || 'Créer un Ticket'}
                </Typography>

                {errorMessage && (
                    <Typography
                        variant="body2"
                        color="error"
                        sx={{
                            gridColumn: '1 / -1',
                            textAlign: 'center',
                            padding: 1,
                            borderRadius: 2,
                            backgroundColor: '#ffe6e6',
                            border: '1px solid red',
                        }}
                    >
                        {errorMessage}
                    </Typography>
                )}

                <FormControl
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    sx={{
                        gridColumn: 'span 2',
                        backgroundColor: '#fff',
                        borderRadius: 2,
                    }}
                >
                    <InputLabel id="categorie-label">Catégorie</InputLabel>
                    <Select
                        labelId="categorie-label"
                        value={categorie}
                        onChange={(e) => setCategorie(e.target.value)}
                        label="Catégorie"
                        sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#d3d3d3',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#1976d2',
                            },
                        }}
                    >
                        <MenuItem value="INCIDENT">Incident</MenuItem>
                        <MenuItem value="PROJET">Projet</MenuItem>
                        <MenuItem value="MAINTENANCE">Maintenance</MenuItem>
                        <MenuItem value="AUTRE">Autre</MenuItem>
                    </Select>
                </FormControl>

                <TextField
                    label="Nom du Ticket"
                    value={titre}
                    onChange={(e) => setTitre(e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                    variant="outlined"
                    sx={{
                        backgroundColor: '#fff',
                        borderRadius: 2,
                        gridColumn: 'span 2',
                    }}
                />

                <TextField
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                    multiline
                    rows={4}
                    variant="outlined"
                    sx={{
                        backgroundColor: '#fff',
                        borderRadius: 2,
                        gridColumn: 'span 2',
                    }}
                />

                {categorie === 'PROJET' && (
                    <TextField
                        label="ID du Projet associé"
                        value={projetId}
                        onChange={(e) => setProjetId(e.target.value)}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        sx={{
                            backgroundColor: '#fff',
                            borderRadius: 2,
                            gridColumn: 'span 2',
                        }}
                    />
                )}

                <TextField
                    type="file"
                    inputProps={{ multiple: true }}
                    onChange={handleFileChange}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    sx={{
                        backgroundColor: '#fff',
                        borderRadius: 2,
                        gridColumn: 'span 2',
                    }}
                />

                {files.length > 0 && (
                    <Box
                        sx={{
                            gridColumn: 'span 2',
                            mt: 2,
                            padding: 2,
                            borderRadius: 2,
                            backgroundColor: '#f5f5f5',
                            border: '1px dashed #1976d2',
                        }}
                    >
                        <Typography variant="subtitle1" gutterBottom>
                            Fichiers sélectionnés :
                        </Typography>
                        <ul style={{ margin: 0, paddingLeft: '20px' }}>
                            {files.map((file, index) => (
                                <li key={index}>
                                    {file.name} ({(file.size / 1024 / 1024).toFixed(2)} MB)
                                </li>
                            ))}
                        </ul>
                    </Box>
                )}

                <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    sx={{ gridColumn: 'span 2', mt: 3 }}
                >
                    <Button
                        onClick={onClose}
                        variant="outlined"
                        color="secondary"
                        sx={{
                            paddingX: 4,
                            paddingY: 1,
                            borderRadius: 20,
                            fontWeight: 'bold',
                        }}
                    >
                        Annuler
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{
                            paddingX: 4,
                            paddingY: 1,
                            borderRadius: 20,
                            fontWeight: 'bold',
                            backgroundColor: '#1976d2',
                            '&:hover': {
                                backgroundColor: '#1565c0',
                            },
                        }}
                    >
                        {ticketData ? 'Modifier' : 'Créer'}
                    </Button>
                </Stack>
            </Box>
        </Paper>
    );

};

export default FormTicket;
