import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, MenuItem, Paper, Stack, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar, Alert } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import frLocale from 'date-fns/locale/fr';
import axios from 'axios';
import config from "../../config";

const FormTache = ({ title, onClose, onSuccess }) => {
    const [nom, setNom] = useState('');
    const [description, setDescription] = useState('');
    const [projetId, setProjetId] = useState('');
    const [projets, setProjets] = useState([]);
    const [dateDebut, setDateDebut] = useState(new Date());
    const [dateFin, setDateFin] = useState(new Date());
    const [errorMessage, setErrorMessage] = useState(null);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        const loadProjets = async () => {
            try {
                const token = sessionStorage.getItem('token');
                const response = await axios.get(`${config.apiUrl}/api/projets`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                setProjets(Array.isArray(response.data) ? response.data : []);
            } catch (error) {
                console.error('Erreur lors du chargement des projets', error);
                setProjets([]);
            }
        };

        loadProjets();
    }, []);

    const formatDateToLocalDateTime = (date) => {
        const localDate = new Date(date);
        const day = String(localDate.getDate()).padStart(2, '0');
        const month = String(localDate.getMonth() + 1).padStart(2, '0');
        const year = String(localDate.getFullYear()).slice(2); // Année sur deux chiffres
        const hours = String(localDate.getHours()).padStart(2, '0');
        const minutes = String(localDate.getMinutes()).padStart(2, '0');
        const seconds = String(localDate.getSeconds()).padStart(2, '0');

        return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setOpenConfirm(true);
    };

    const handleConfirmSubmit = async () => {
        if (!dateDebut || !dateFin) {
            setSnackbarMessage("Veuillez renseigner les dates de début et de fin.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        const formattedDateDebut = formatDateToLocalDateTime(dateDebut);
        const formattedDateFin = formatDateToLocalDateTime(dateFin);

        try {
            const token = sessionStorage.getItem("token");
            const response = await axios.post(
                `${config.apiUrl}/api/taches`,
                {
                    nom,
                    description,
                    projet: projetId ? { id: projetId } : null,
                    dateDebut: formattedDateDebut,
                    dateFin: formattedDateFin,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status >= 200 && response.status < 300) {
                setSnackbarMessage("Tâche créée avec succès.");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
                setTimeout(() => {
                    onClose();
                    if (onSuccess) {
                        onSuccess(response.data);
                    }
                }, 1000);
            }
        } catch (error) {
            console.error("Erreur lors de la création de la tâche :", error);
            setSnackbarMessage("Erreur lors de la création de la tâche.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
        setOpenConfirm(false);
    };


    const handleCancel = () => {
        setOpenConfirm(false);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Paper
            elevation={6}
            sx={{
                padding: 4,
                maxWidth: 600,
                margin: 'auto',
                mt: 5,
                borderRadius: 3,
                backgroundColor: '#f9f9f9',
            }}
        >
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    display: 'grid',
                    gap: 3,
                    gridTemplateColumns: '1fr',
                }}
            >
                <Typography
                    variant="h5"
                    align="center"
                    gutterBottom
                    sx={{
                        fontWeight: 600,
                        color: '#1976d2',
                        marginBottom: 2,
                    }}
                >
                    {title || 'Créer une Tâche'}
                </Typography>

                {errorMessage && (
                    <Typography
                        variant="body2"
                        color="error"
                        align="center"
                        sx={{
                            padding: 1,
                            border: '1px solid red',
                            borderRadius: 1,
                            backgroundColor: '#ffe6e6',
                        }}
                    >
                        {errorMessage}
                    </Typography>
                )}

                <TextField
                    label="Nom"
                    value={nom}
                    onChange={(e) => setNom(e.target.value)}
                    fullWidth
                    required
                    variant="outlined"
                    sx={{
                        backgroundColor: '#fff',
                        borderRadius: 2,
                    }}
                />

                <TextField
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    fullWidth
                    required
                    multiline
                    rows={4}
                    variant="outlined"
                    sx={{
                        backgroundColor: '#fff',
                        borderRadius: 2,
                    }}
                />

                <TextField
                    select
                    label="Projet associé"
                    value={projetId || ''}
                    onChange={(e) => setProjetId(e.target.value)}
                    fullWidth
                    variant="outlined"
                    helperText="Choisissez un projet"
                    sx={{
                        backgroundColor: '#fff',
                        borderRadius: 2,
                    }}
                >
                    <MenuItem value="">Aucun projet</MenuItem>
                    {Array.isArray(projets) && projets.length > 0 ? (
                        projets.map((projet) => (
                            <MenuItem key={projet.id} value={projet.id}>
                                {projet.nom}
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem disabled>Aucun projet disponible</MenuItem>
                    )}
                </TextField>

                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
                    <DateTimePicker
                        label="Date de début"
                        value={dateDebut}
                        onChange={(newValue) => setDateDebut(newValue)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                required
                                variant="outlined"
                                sx={{
                                    backgroundColor: '#fff',
                                    borderRadius: 2,
                                }}
                            />
                        )}
                    />
                    <DateTimePicker
                        label="Date de fin"
                        value={dateFin}
                        onChange={(newValue) => setDateFin(newValue)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                required
                                variant="outlined"
                                sx={{
                                    backgroundColor: '#fff',
                                    borderRadius: 2,
                                }}
                            />
                        )}
                    />
                </LocalizationProvider>

                <Stack direction="row" spacing={2} justifyContent="center">
                    <Button
                        onClick={onClose}
                        variant="outlined"
                        color="secondary"
                        sx={{
                            paddingX: 4,
                            paddingY: 1,
                            borderRadius: 20,
                            fontWeight: 'bold',
                        }}
                    >
                        Annuler
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{
                            paddingX: 4,
                            paddingY: 1,
                            borderRadius: 20,
                            fontWeight: 'bold',
                            backgroundColor: '#1976d2',
                            '&:hover': {
                                backgroundColor: '#1565c0',
                            },
                        }}
                    >
                        Créer
                    </Button>
                </Stack>
            </Box>

            <Dialog open={openConfirm} onClose={handleCancel}>
                <DialogTitle
                    sx={{
                        fontWeight: 'bold',
                        color: '#1976d2',
                    }}
                >
                    Confirmer la création
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        Êtes-vous sûr de vouloir créer cette tâche avec les informations fournies ?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="secondary">
                        Annuler
                    </Button>
                    <Button onClick={handleConfirmSubmit} color="primary">
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={4000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Paper>
    );

};

export default FormTache;
